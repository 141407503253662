
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import { Empresa } from '@/core/models/cadastros';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { TableToExcel } from '@/assets/scripts/helper';
 
@Component
export default class ListaEmpresa extends mixins(Vue,ListPage) {
  item = new Empresa();
  service = new EmpresaService();
 
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  
  titulo: string = 'Empresa';
  subTitulo: string = 'Lista das Empresas para o uso no sistema';
  temBuscaRapida: boolean = true;
 
  options: any = {
    itemsPerPage: 15
  }; 
   
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Cnpj', value: 'cpfCnpj' },
    { text: 'Razão social', value: 'nome' },
    { text: 'Telefone', value: 'telefone1' },
    { text: 'Email', value: 'email' },
  ]; 

   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Empresas', disabled: true, href: '/financeiro/painel/despesas'}
  ]
  
  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true; 

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, '', 'id, nome, telefone1,email,cpfCnpj', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false));
  } 
    
  Novo(){
    this.item = new Empresa();
    this.item.responsavel.dataNascimento = new Date().yyyymmdd();
    this.dialogCadastro = true;
  } 

  Editar(item){
    this.service.ObterPorId(item.id, "Documentos").then(res => {    
      this.service.CarregarFoto(item.id).then(image => {
        this.item = res.data;
        this.item.responsavel.dataNascimento = new Date(res.data.responsavel.dataNascimento).yyyymmdd();
        this.item.logo = image.data;
        this.dialogCadastro = true;
      })
   })
  }

  ExportarExcel(tabela: any, worksheet?: string) {
    TableToExcel(tabela, worksheet);
  }
 
  mounted(){ 
    //this.Atualizar();
  }
}
